import React, {useState, useEffect} from 'react';
import ReactMarkdown from 'react-markdown';
import {useParams} from 'react-router-dom';
import "./MarkdownPost.css";
import {Container, Box} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';

const renderImage = (props) => {
    return (
        <div className="markdown-image">
            <img {...props} style={{ maxWidth: '100%', height: 'auto', ...props.style }} />
        </div>
    );
};

const MarkdownPost = () => {
    const {slug} = useParams();
    const [markdownContent, setMarkdownContent] = useState('');

    useEffect(() => {
        import(`../blog_posts/${slug}.md`)
            .then(module => {
                fetch(module.default)
                    .then(response => response.text())
                    .then(content => {
                        setMarkdownContent(content);
                    });
            })
            .catch(error => {
                console.error("Failed to load markdown file:", error);
                setMarkdownContent(null);
            });
    }, [slug]);

    if (markdownContent === null) return <div>Post not found</div>;
    
    return (
        <Box className="markdown-container">
            <Container maxWidth="md">
                <a href="/" className="back-button" aria-label="back">
                    <ArrowBack/> Back to index
                </a>
                <article className="markdown-content">
                    <ReactMarkdown 
                        components={{ 
                            img: renderImage,
                            // Add specific rendering for other elements if needed
                        }}
                    >
                        {markdownContent}
                    </ReactMarkdown>
                </article>
            </Container>
        </Box>
    );
}

export default MarkdownPost;