import React, { useState, useRef, useEffect } from 'react';
import './YearlyInterview.css';

const questions = [
  // "Looking back at this year, what accomplishment or achievement are you most proud of, and how did it impact your life?",
  // "Tell me about a moment this year that really challenged you - what was the situation, how did you handle it, and what did it teach you?",
  // "When you reflect on the past year, what people, experiences, or circumstances fill you with the most gratitude, and why?",
  // "As you look ahead to the coming year, what meaningful goals or aspirations would you like to pursue, and what steps will you take to achieve them?",
  // "What's the most significant lesson or insight you've gained this year, and how has it changed your perspective or approach to life?",
  "What moment made you feel truly happy this year?",
  "What do you value most in yourself right now?",
  "Which trait do you admire most in someone you care about?",
  "Which song or artist speaks to you the most lately?",
  "What do you fear or worry about these days?",
  "Who or what has been your greatest inspiration recently?",
  "If you could gain one new talent overnight, what would it be?",
  "Which word best sums up the past year for you?",
  "What do you hope to change or learn by next year?",
  "If you could send a message to your future self, what would it say?"
];

export default function YearlyInterview() {
  const [recording, setRecording] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showQuestion, setShowQuestion] = useState(false);
  
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const cameraStreamRef = useRef(null);

  // 1) Request camera + mic access, attach to hidden <video>
  useEffect(() => {
    (async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        cameraStreamRef.current = stream;

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          await videoRef.current.play().catch(() => {});
        }
      } catch (err) {
        console.error("Error accessing media devices:", err);
      }
    })();

    return () => {
      // On cleanup, stop all tracks
      if (cameraStreamRef.current) {
        cameraStreamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  // 2) Continuously draw the hidden video onto the canvas, plus question overlay if needed
  useEffect(() => {
    let fps = 30; // frames per second for the canvas
    let intervalId;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const video = videoRef.current;

    function drawFrame() {
      if (!video || video.readyState < 2) return;
      
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      if (showQuestion) {
        ctx.fillStyle = 'rgba(0,0,0,0.5)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const maxWidth = canvas.width * 0.8; // 80% of canvas width
        const lineHeight = 32;
        const x = canvas.width / 2;
        let y = canvas.height / 2;

        ctx.fillStyle = '#fff';
        ctx.font = '24px Inter';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        const words = questions[currentQuestionIndex].split(' ');
        let line = '';
        
        words.forEach(word => {
          const testLine = line + word + ' ';
          const metrics = ctx.measureText(testLine);
          
          if (metrics.width > maxWidth && line !== '') {
            ctx.fillText(line, x, y);
            line = word + ' ';
            y += lineHeight;
          } else {
            line = testLine;
          }
        });
        ctx.fillText(line, x, y);
      }
    }

    // Draw at the set FPS using setInterval
    intervalId = setInterval(drawFrame, 1000 / fps);

    return () => clearInterval(intervalId);
  }, [showQuestion, currentQuestionIndex]);

  // 3) Start a single MediaRecorder on the canvas stream + audio from camera
  const startRecording = () => {
    if (!canvasRef.current) return;
    if (recording) return;

    recordedChunksRef.current = []; // reset recorded chunks

    // Canvas (video) part
    const canvasStream = canvasRef.current.captureStream(30); // target 30 fps
    // Add microphone track(s) from the camera stream
    if (cameraStreamRef.current) {
      const audioTracks = cameraStreamRef.current.getAudioTracks();
      audioTracks.forEach(track => canvasStream.addTrack(track));
    }

    // Create MediaRecorder from canvas+audio
    const mediaRecorder = new MediaRecorder(canvasStream, {
      mimeType: 'video/webm; codecs=vp8,opus',
    });

    // Push data as it becomes available
    mediaRecorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        recordedChunksRef.current.push(e.data);
      }
    };

    // When we stop, create final blob + download
    mediaRecorder.onstop = () => {
      const blob = new Blob(recordedChunksRef.current, { type: 'video/webm' });
      const url = URL.createObjectURL(blob);

      // Auto-download
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'yearly-interview.webm';
      document.body.appendChild(a);
      a.click();

      // Cleanup
      URL.revokeObjectURL(url);
      recordedChunksRef.current = [];
    };

    mediaRecorder.start();
    mediaRecorderRef.current = mediaRecorder;
    setRecording(true);

    // Optionally show the first question overlay for 3s
    showCurrentQuestion();
  };

  const stopRecording = () => {
    if (!mediaRecorderRef.current) return;
    if (mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }
    setRecording(false);
  };

  // Show the question text overlay for 3 seconds
  const showCurrentQuestion = () => {
    setShowQuestion(true);
    setTimeout(() => {
      setShowQuestion(false);
    }, 3000);
  };

  // Proceed to next question
  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
      // automatically show next question overlay
      setTimeout(() => {
        showCurrentQuestion();
      }, 500);
    } else {
      // If last question, stop
      stopRecording();
    }
  };

  return (
    <div className="yearly-interview">
      <div className="interview-container">
        <div className="left-section">
          <header className="interview-header">
            <h1>The Interview</h1>
            <p className="subtitle">Make a video to watch in 2035.</p>
          </header>
          
          {recording && (
            <div className="recording-indicator">
              <div className="recording-dot"></div>
              <span className="recording-text">Recording</span>
            </div>
          )}

          <div className="question-status">
            <div className="progress">
              <span className="question-counter">
                Question {currentQuestionIndex + 1} of {questions.length}
              </span>
              <div className="progress-bar">
                <div 
                  className="progress-fill" 
                  style={{width: `${((currentQuestionIndex + 1) / questions.length) * 100}%`}}
                />
              </div>
            </div>
            <p className="current-question">{questions[currentQuestionIndex]}</p>
          </div>

          <div className="controls">
            {!recording ? (
              <button className="primary-button" onClick={startRecording}>
                Start Your Interview
              </button>
            ) : (
              <div className="button-group">
                <button className="secondary-button" onClick={stopRecording}>
                  Finish & Download
                </button>
                <button 
                  className="primary-button"
                  onClick={handleNextQuestion} 
                  disabled={!recording}
                >
                  {currentQuestionIndex < questions.length - 1 ? 'Next Question' : 'Complete Interview'}
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="right-section">
          <video ref={videoRef} style={{ display: 'none' }} muted playsInline />
          
          <div className="canvas-container">
            <canvas ref={canvasRef} width={640} height={480} />
          </div>
        </div>
      </div>
    </div>
  );
}
