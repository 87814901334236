import React, { useState } from 'react';
import { Video } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import VideoCard from './VideoCard';
import RecordFocusGif from './RecordFocusGif';
import { refreshAccessToken } from '../utils/auth';

const API_URL = window.location.hostname.includes("sashachisler")
  ? 'https://api.sashachisler.com'
  : 'http://127.0.0.1:5000';

const TodayCell = ({ todayVideo, setSelectedVideo, isAuthenticated, currentStreak }) => {
  const [isRecording, setIsRecording] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    if (!todayVideo) {
      if (isAuthenticated) {
        setIsRecording(true);
      } else {
        navigate('/daily');
      }
    }
  };

  const handleRecordingComplete = async () => {
    try {
      console.log('Upload successful:');
      window.location.reload();
    } catch (error) {
      console.error('Error uploading gif:', error);
      if (error.message.includes('No authentication token')) {
        navigate('/daily');
      } else {
        console.error('Error uploading gif 2', error);
      }
    } finally {
      setIsRecording(false);
    }
  };

  return (
    <div className="mb-12">
      <div className="text-sm font-medium text-gray-600 mb-3">
        TODAY
      </div>
      <div 
        className={`
          max-w-[200px]
          aspect-square
          rounded-xl border-4
          ${!todayVideo 
            ? currentStreak > 0
              ? 'border-dashed border-yellow-500 cursor-pointer hover:bg-yellow-100 transition-colors' 
              : 'border-dashed border-red-500 cursor-pointer hover:bg-red-100 transition-colors'
            : 'border-green-500'
          }
          ${!todayVideo 
            ? currentStreak > 0
              ? 'animate-pulse bg-yellow-50'
              : 'animate-pulse bg-red-50'
            : 'bg-green-500'
          }
          flex items-center justify-center
          shadow-sm
        `}
        onClick={handleClick}
      >
        {!todayVideo && (
          <div className={`text-center p-4 hover:scale-105 transition-transform
            ${currentStreak > 0 ? 'text-yellow-600' : 'text-red-600'}`}>
            <Video className="w-12 h-12 mx-auto mb-2" />
            <span className="text-sm font-medium">
              {isAuthenticated 
                ? currentStreak > 0
                  ? "Record Today To Keep Streak"
                  : "Start New Streak Today"
                : "Sign In To Record Progress"}
            </span>
          </div>
        )}
        {todayVideo && (
          <VideoCard 
            video={todayVideo} 
            onClick={(video) => setSelectedVideo(video)}
          />
        )}
      </div>

      {isRecording && (
        <RecordFocusGif
          onClose={() => setIsRecording(false)}
          onRecordingComplete={handleRecordingComplete}
        />
      )}
    </div>
  );
};

export default TodayCell; 