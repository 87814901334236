import React from 'react';

const MotivationalMessage = ({ currentStreak }) => (
  <div className="mt-6 text-center text-gray-600">
    {currentStreak > 0 ? (
      <p className="text-green-600 font-medium">
        🔥 {currentStreak} day learning streak! Wow...
      </p>
    ) : (
      <p>Start your streak today!</p>
    )}
  </div>
);

export default MotivationalMessage; 