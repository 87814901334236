import React, { useEffect, useState } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';

const VideoModal = ({ video, onClose, onNext, onPrevious, totalVideos, currentIndex }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [loadError, setLoadError] = useState(false);

    // Reset loading state when video changes
    useEffect(() => {
        setIsLoading(true);
        setLoadError(false);
    }, [video]);

    React.useEffect(() => {
        const handleKeyDown = (e) => {
            if (!video) return;
            if (e.key === 'ArrowLeft') onPrevious();
            if (e.key === 'ArrowRight') onNext();
            if (e.key === 'Escape') onClose();
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [video, onPrevious, onNext, onClose]);

    if (!video) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-75" onClick={onClose}>
            <div className="relative bg-white rounded-lg max-w-3xl w-full max-h-[80vh] overflow-hidden" onClick={(e) => e.stopPropagation()}>
                {/* Navigation Arrows */}
                {currentIndex > 0 && (
                    <button 
                        onClick={onPrevious}
                        className="absolute left-4 top-1/2 -translate-y-1/2 text-white/80 hover:text-white
                                 bg-black/20 hover:bg-black/40 p-2 rounded-full transition-all"
                        aria-label="Previous image"
                    >
                        <ChevronLeft size={24} />
                    </button>
                )}
                
                {currentIndex < totalVideos - 1 && (
                    <button 
                        onClick={onNext}
                        className="absolute right-4 top-1/2 -translate-y-1/2 text-white/80 hover:text-white
                                 bg-black/20 hover:bg-black/40 p-2 rounded-full transition-all"
                        aria-label="Next image"
                    >
                        <ChevronRight size={24} />
                    </button>
                )}

                {/* Close Button */}
                <button
                    onClick={onClose}
                    className="absolute -top-12 right-0 text-white/80 hover:text-white transition-colors"
                    aria-label="Close modal"
                >
                    <X size={24} />
                </button>

                {/* Loading overlay */}
                {isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-900/95 rounded-lg">
                        <div className="text-center">
                            <div className="animate-spin rounded-full h-16 w-16 border-4 border-gray-400 border-t-white mx-auto mb-4"></div>
                            <p className="text-white/90 text-lg font-medium">Loading your focused moment...</p>
                            <p className="text-white/60 text-sm mt-2">This might take a few seconds</p>
                        </div>
                    </div>
                )}

                {/* Error state */}
                {loadError && (
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-900/95 rounded-lg">
                        <div className="text-center">
                            <p className="text-white/90 text-lg font-medium mb-2">Oops! Couldn't load this video.</p>
                            <button 
                                onClick={() => {
                                    setLoadError(false);
                                    setIsLoading(true);
                                }}
                                className="text-blue-400 hover:text-blue-300 underline mt-2"
                            >
                                Try again
                            </button>
                        </div>
                    </div>
                )}

                {/* Video/GIF Display */}
                <div className="bg-gray-900 rounded-lg overflow-hidden">
                    <img 
                        src={video.url} 
                        alt="Recording" 
                        className={`w-full h-auto ${isLoading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300`}
                        onLoad={() => setIsLoading(false)}
                        onError={() => {
                            setIsLoading(false);
                            setLoadError(true);
                        }}
                    />
                    
                    {/* Date and Counter overlays */}
                    <div className="absolute bottom-4 w-full px-4 flex justify-between">
                        <div className="bg-black/60 text-white px-3 py-1 rounded-full text-sm">
                            {new Date(video.metadata.date).toLocaleDateString()}
                        </div>
                        <div className="bg-black/60 text-white px-3 py-1 rounded-full text-sm">
                            {currentIndex + 1} / {totalVideos}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoModal; 