import { refreshAccessToken, clearAuth } from './auth';

import { API_URL } from '../config';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const originalFetch = window.fetch;

// Override the global fetch
window.fetch = async function (...args) {
  let [resource, config] = args;

  // Only intercept requests to our API
  if (typeof resource === 'string' && resource.includes(API_URL)) {
    config = config || {};
    config.headers = config.headers || {};

    // Don't add auth header for login/register/refresh
    if (!resource.includes('/login') && 
        !resource.includes('/register') && 
        !resource.includes('/api/refresh')) {
      const accessToken = localStorage.getItem('access_token');
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }

    try {
      let response = await originalFetch(resource, config);

      // Handle 401 responses
      if (response.status === 401) {
        if (isRefreshing) {
          // Wait for the other refresh to complete
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          }).then(() => {
            config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
            return originalFetch(resource, config);
          });
        }

        isRefreshing = true;

        try {
          const refreshed = await refreshAccessToken();
          isRefreshing = false;

          if (refreshed) {
            config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
            processQueue(null, localStorage.getItem('access_token'));
            return originalFetch(resource, config);
          } else {
            processQueue(new Error('Failed to refresh token'));
            clearAuth();
            // Redirect to login if needed
            if (window.location.pathname !== '/daily') {
              window.location.href = '/daily';
            }
            throw new Error('Authentication failed');
          }
        } catch (error) {
          isRefreshing = false;
          processQueue(error);
          throw error;
        }
      }

      return response;
    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
  }

  // Pass through for non-API requests
  return originalFetch(resource, config);
};