import React, { useState, useEffect } from 'react';
import StatsBar from './StatsBar';
import TitleSection from './TitleSection';
import TodayCell from './TodayCell';
import TimelineSection from './TimelineSection';
import VideoModal from './VideoModal';
import MotivationalMessage from './MotivationalMessage';
import { formatDate } from '../utils/dateUtils';
import './EveryDay.css';
import { useParams } from 'react-router-dom';
import FocusPet from './FocusPet';
import { refreshAccessToken } from '../utils/auth';

const API_URL = window.location.hostname.includes("sashachisler")
  ? 'https://api.sashachisler.com'
  : 'http://127.0.0.1:5000';

const StreakGallery = () => {
  const { username: profileUsername } = useParams();
  const [videos, setVideos] = useState([]);
  const [currentStreak, setCurrentStreak] = useState(0);
  const [videosByDate, setVideosByDate] = useState({});
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authMode, setAuthMode] = useState('login'); // 'login' or 'register' 
  const [authError, setAuthError] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const authenticateUser = async (endpoint) => {
    setAuthError('');
    try {
      const response = await fetch(`${API_URL}/${endpoint}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();
      if (response.ok) {
        // Store both tokens
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);
        localStorage.setItem('username', data.username);
        setIsAuthenticated(true);
        fetchVideos();
      } else {
        setAuthError(data.error || 'Authentication failed');
      }
    } catch (error) {
      setAuthError('Network error');
      console.error('Error authenticating:', error);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    authenticateUser('login');
  };

  const handleRegister = (e) => {
    e.preventDefault();
    authenticateUser('register');
  };

  const getRecentDays = () => {
    const days = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Find the earliest video date
    let earliestDate = today;
    Object.keys(videosByDate).forEach(dateKey => {
      const videoDate = new Date(dateKey);
      if (videoDate < earliestDate) {
        earliestDate = videoDate;
      }
    });

    // Generate days from today back to earliest video
    const tempDate = new Date(today);
    while (tempDate >= earliestDate) {
      const dateKey = tempDate.toISOString().split('T')[0];
      const isInStreak = videosByDate[dateKey]; // simplified streak check

      days.push({
        date: new Date(tempDate),
        dateKey,
        hasVideo: !!videosByDate[dateKey],
        isToday: tempDate.getTime() === today.getTime(),
        isInStreak,
        videos: videosByDate[dateKey] || [],
      });
      tempDate.setDate(tempDate.getDate() - 1);
    }

    return days;
  };

  const groupDaysByMonth = (days) => {
    return days.reduce((acc, day) => {
      const monthYear = day.date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(day);
      return acc;
    }, {});
  };

  const getTodayVideo = () => {
    const todayKey = new Date().toISOString().split('T')[0];
    return videosByDate[todayKey]?.[0] || null;
  };

  const calculateStreak = (videosByDate) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // this should count as 0 or 1 if today has a video, but shouldn't jeopardize streak
    let streak = 0;  // Today counts as 
    const todayKey = today.toISOString().split('T')[0];
    if (videosByDate[todayKey]) {
      streak = 1; // Count today as 1 if there's a video
    }
    let currentDate = new Date(today);
    currentDate.setDate(currentDate.getDate() - 1);  // Start checking from yesterday
    
    // Check previous days
    while (true) {
      const dateKey = currentDate.toISOString().split('T')[0];
      if (!videosByDate[dateKey]) break;
      streak++;
      currentDate.setDate(currentDate.getDate() - 1);
    }

    setCurrentStreak(streak);
  };

  const recentDays = getRecentDays();
  const groupedDays = groupDaysByMonth(recentDays.filter((day) => !day.isToday));
  const isOwnProfile = !profileUsername || 
    (isAuthenticated && profileUsername === localStorage.getItem('username'));

  const fetchVideos = async () => {
    try {
      const url = new URL(`${API_URL}/api/videos`);
      if (profileUsername) {
        url.searchParams.append('username', profileUsername);
      }

      const headers = {};
      if (!profileUsername) {
        headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
      }

      const response = await fetch(url, { headers });
      
      // Handle 401 Unauthorized
      if (response.status === 401) {
        const refreshed = await refreshAccessToken();
        if (refreshed) {
          // Retry the fetch with new access token
          headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
          const retryResponse = await fetch(url, { headers });
          if (!retryResponse.ok) throw new Error('Failed to fetch videos');
          const data = await retryResponse.json();
          // ... rest of your video processing code
        } else {
          // Force re-authentication
          setIsAuthenticated(false);
          return;
        }
      }

      const data = await response.json();

      const sortedVideos = data.sort((a, b) =>
        new Date(b.metadata.date) - new Date(a.metadata.date)
      );
      setVideos(sortedVideos);

      const grouped = sortedVideos.reduce((acc, video) => {
        const dateKey = new Date(video.metadata.date).toISOString().split('T')[0];
        if (!acc[dateKey]) acc[dateKey] = [];
        acc[dateKey].push(video);
        return acc;
      }, {});
      setVideosByDate(grouped);

      // Calculate streak
      calculateStreak(grouped);
    } catch (err) {
      console.error('Error fetching videos:', err);
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          const response = await fetch(`${API_URL}/whoami`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if (response.ok) {
            const data = await response.json();
            localStorage.setItem('username', data.username);  // Store username
            setIsAuthenticated(true);
          } else {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('username');  // Clean up username too
          }
        } catch (error) {
          console.error('Auth check failed:', error);
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('username');  // Clean up username too
        }
      }
      setIsLoading(false);
    };
    
    checkAuth();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      fetchVideos();
    }
  }, [isLoading, profileUsername, isOwnProfile]);

  const handleVideoChange = (index) => {
    setSelectedVideoIndex(index);
  };

  const handleNextVideo = () => {
    if (selectedVideoIndex !== null && selectedVideoIndex < videos.length - 1) {
      handleVideoChange(selectedVideoIndex + 1);
    }
  };

  const handlePreviousVideo = () => {
    if (selectedVideoIndex !== null && selectedVideoIndex > 0) {
      handleVideoChange(selectedVideoIndex - 1);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen w-full bg-[#1cb0f6] flex items-center justify-center">
        <div className="text-white text-xl">Loading...</div>
      </div>
    );
  }

  if (!isAuthenticated && !profileUsername) {
    return (
      <div className="min-h-screen w-full bg-[#1cb0f6] flex flex-col items-center justify-center p-4">
        <div className="w-full max-w-md">
          <div className="text-center mb-8">
            <div className="text-6xl mb-4">🎯</div>
            <h1 className="text-5xl font-black text-white mb-2 tracking-tight">
              Daily Working Face
            </h1>
            <p className="text-xl text-white/90 font-medium">
              Highest Focus Very Strict Discipline Streak Machine
            </p>
          </div>

          <div className="bg-white rounded-[2rem] p-8 shadow-xl">
            <div className="flex flex-col items-center space-y-5">
              {authError && (
                <div className="w-full bg-red-50/95 backdrop-blur p-4 rounded-2xl 
                              border-2 border-red-200 animate-shake">
                  <p className="text-sm text-red-600 text-center font-medium">
                    {authError}
                  </p>
                </div>
              )}

              <form className="w-full space-y-4" onSubmit={authMode === 'login' ? handleLogin : handleRegister}>
                <div className="space-y-3">
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="✨ Username"
                    className="w-full h-16 px-6 rounded-2xl text-lg font-medium
                             bg-[#f6f7f8] border-2 border-transparent
                             focus:border-[#1cb0f6] focus:bg-white
                             transition-all duration-200 outline-none"
                  />
                  
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="🔒 Password"
                    className="w-full h-16 px-6 rounded-2xl text-lg font-medium
                             bg-[#f6f7f8] border-2 border-transparent
                             focus:border-[#1cb0f6] focus:bg-white
                             transition-all duration-200 outline-none"
                  />
                </div>

                {authMode === 'login' ? (
                  <button
                    type="submit"
                    className="w-full h-16 bg-[#1cb0f6] mt-6
                             text-white rounded-2xl text-xl font-black
                             transition-all duration-200 
                             hover:bg-[#1899d6] hover:-translate-y-0.5
                             active:translate-y-0
                             shadow-[0_4px_0_rgb(24,153,214)]
                             active:shadow-[0_0_0]"
                  >
                    Continue →
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="w-full h-16 bg-[#58cc02] mt-6
                             text-white rounded-2xl text-xl font-black
                             transition-all duration-200 
                             hover:bg-[#4baf01] hover:-translate-y-0.5
                             active:translate-y-0
                             shadow-[0_4px_0_rgb(75,175,1)]
                             active:shadow-[0_0_0]"
                  >
                    Create Account →
                  </button>
                )}
              </form>

              <div className="w-full pt-4">
                <button
                  onClick={() => setAuthMode(authMode === 'login' ? 'register' : 'login')}
                  className="w-full h-16 bg-white border-2 border-[#1cb0f6]
                           text-[#1cb0f6] rounded-2xl text-lg font-bold
                           hover:bg-[#1cb0f6]/5 transition-colors"
                >
                  {authMode === 'login' 
                    ? "New here? Create free account" 
                    : 'Already have an account? Sign in'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto">
        <StatsBar 
          currentStreak={currentStreak} 
          startDate={formatDate(new Date())} 
          username={profileUsername || username}
          isOwnProfile={isOwnProfile}
          isAuthenticated={isAuthenticated}
          />
          {!isAuthenticated && !isOwnProfile && (
            <div className="flex justify-end -mt-2 mb-2">
              <a 
                href="/daily"
                className="text-gray-400 hover:text-gray-600 text-s font-medium
                           transition-colors duration-200"
              >
                Start Your Own Streak
              </a>
            </div>
          )}
        <TitleSection />
        {isOwnProfile && (
          <TodayCell 
            todayVideo={getTodayVideo()} 
            setSelectedVideo={(video) => handleVideoChange(videos.indexOf(video))}
            isAuthenticated={isAuthenticated}
            currentStreak={currentStreak}
            onRecordingComplete={fetchVideos}
          />
        )}
        <TimelineSection 
          groupedDays={groupedDays} 
          setSelectedVideo={(video) => handleVideoChange(videos.indexOf(video))} 
        />
        {selectedVideoIndex !== null && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
               onClick={(e) => e.target === e.currentTarget && handleVideoChange(null)}>
            <div className="max-w-2xl mx-auto">
              <VideoModal 
                video={videos[selectedVideoIndex]}
                onClose={() => handleVideoChange(null)}
                onNext={handleNextVideo}
                onPrevious={handlePreviousVideo}
                totalVideos={videos.length}
                currentIndex={selectedVideoIndex}
              />
            </div>
          </div>
        )}
        <MotivationalMessage currentStreak={currentStreak} />
        <FocusPet 
          lastFed={getTodayVideo()?.metadata.date}
          streakCount={currentStreak}
        />
      </div>
    </div>
  );
};

export default StreakGallery;
