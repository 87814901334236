// references to Blog Posts describing my experiences, written in markdown


export const blogPosts = [
    {
        title: "Incident Counter",
        slug: "incident-counter",
        date: "2024-01-15", // Adjust this date as needed
        description: "A Bali trip full of incidents.",
        component: "IncidentCounter"  // This indicates it's a static component
    },
    {
        id: 2,
        title: "We made a game where you work as God",
        date: "23 July 2023",
        description: "",
        link: "https://www.godswork.live/",
        moneySpent: 300,
        moneyEarned: 0,
        humanConnections: 500,
        previewImageLink: "https://i.imgur.com/dZ1xieZ.jpg",
        slug: 'godswork'
    },
    {
        id: 0,
        title: "Ukranian refugee project",
        date: "26 Feb 2022",
        description: "",
        link: "https://www.instagram.com/p/CahcYxisMlg/",
        moneySpent: 0,
        moneyEarned: 0,
        humanConnections: 170468,
        previewImageLink: "https://i.imgur.com/5NqKcD0.jpg",
        slug: 'ukraine_aid'
    },

];
