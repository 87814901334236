import React, {useState} from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Calendar, Clock, Video } from 'lucide-react';
import './EveryDay.css'


const VideoCard = ({ video, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative w-full h-full cursor-pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => onClick(video)}
    >
      <img
        src={video.url}
        alt={video.filename}
        className="w-full h-full object-cover rounded-lg"
      />
      
      {isHovered && (
        <div className="absolute inset-0 bg-black/60 flex items-center justify-center rounded-lg">
          <Clock className="w-4 h-4 text-white" />
        </div>
      )}
    </div>
  );
};


export default VideoCard; 