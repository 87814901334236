import React from 'react';

const TitleSection = () => (
  <div className="mb-8">
    <h1 className="text-5xl font-bold text-gray-800 mb-5">
      Daily Working Face
    </h1>
    <p className="text-gray-600 text-xl mt-1">
      Deep focusing with a shitty attention span.  
    </p>
</div>
);

export default TitleSection; 