import React from 'react';
import TimelineDay from './TimelineDay';

const TimelineSection = ({ groupedDays, setSelectedVideo, firstVideoDate }) => (
  <div className="border-t pt-8">
    <h2 className="text-sm font-medium text-gray-600 mb-4">PREVIOUS DAYS</h2>
    {Object.entries(groupedDays).map(([monthYear, days]) => (
      <div key={monthYear} className="mb-8">
        <h3 className="text-lg font-medium text-gray-700 mb-3">{monthYear}</h3>
        <div className="grid grid-cols-7 gap-2">
          {days.map((day) => (
            <TimelineDay 
              key={day.dateKey} 
              day={day} 
              setSelectedVideo={setSelectedVideo}
              firstVideoDate={firstVideoDate}
            />
          ))}
        </div>
      </div>
    ))}
  </div>
);

export default TimelineSection; 