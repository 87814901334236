import React from 'react';
import VideoCard from './VideoCard';

const TimelineDay = ({ day, setSelectedVideo, firstVideoDate }) => {
  // Only consider it a break if it's after the first video date
  const isBreakInStreak = !day.hasVideo && 
    day.date < new Date() && 
    (!firstVideoDate || day.date >= firstVideoDate);

  // Don't render anything before the first video
  if (firstVideoDate && day.date < firstVideoDate) {
    return null;
  }

  return (
    <div className={`
      aspect-square relative rounded-lg overflow-hidden
      ${day.hasVideo 
        ? 'bg-green-500 shadow-md transform hover:scale-105 transition-transform' 
        : isBreakInStreak
          ? 'bg-red-100 border border-red-200'
          : 'bg-gray-100'
      }
    `}>
      {day.hasVideo ? (
        <VideoCard 
          video={day.videos[0]} 
          onClick={(video) => setSelectedVideo(video)}
        />
      ) : (
        <div className="w-full h-full flex items-center justify-center flex-col">
          {isBreakInStreak ? (
            <>
              <span className="text-2xl mb-1">🦉</span>
              <span className="text-xs text-red-400 text-center px-1">
                missed day
              </span>
            </>
          ) : (
            <span className="text-xs text-gray-400">
              {day.date.getDate()}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default TimelineDay; 