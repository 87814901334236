import React from 'react';
import { Calendar, Clock } from 'lucide-react';

const StatsBar = ({ currentStreak, startDate }) => (
  <div className="flex items-center justify-between mb-8">
    <div className="flex items-center gap-2">
      <Calendar className="w-6 h-6 text-green-600" />
      <span className="text-2xl font-bold text-gray-800">{currentStreak}</span>
      <span className="text-sm text-gray-600">day streak</span>
    </div>
    <div className="flex items-center gap-2">
      <Clock className="w-5 h-5 text-gray-600" />
      <span className="text-sm text-gray-600">Since {startDate}</span>
    </div>
  </div>
);

export default StatsBar; 