import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const Timeline = ({ blogPosts }) => {
    console.log("Timeline rendering with posts:", blogPosts);
    
    return (
        <div className="projects-grid">
            {blogPosts?.map((post) => (
                <RouterLink 
                    key={post.slug}
                    to={post.component === 'IncidentCounter' ? '/incident-counter' : `/posts/${post.slug}`}
                    className="project-card"
                >
                    <div className="card-content">
                        <div className="card-header">
                            <h3>{post.title}</h3>
                            {post.previewImageLink && (
                                <div className="post-icon">
                                    <img 
                                        src={post.previewImageLink} 
                                        alt={post.title}
                                    />
                                </div>
                            )}
                        </div>
                        <p>{post.description}</p>
                    </div>
                </RouterLink>
            ))}
        </div>
    );
};

export default Timeline;