import React, { useState, useEffect } from 'react';
import './FocusPet.css';

const FocusPet = ({ lastFed, streakCount }) => {
    const [mood, setMood] = useState('hungry'); // hungry, happy, sleeping
    const [size, setSize] = useState(1);

    useEffect(() => {
        // Calculate time since last fed
        const timeSinceLastFed = lastFed ? Date.now() - new Date(lastFed) : Infinity;
        const isHungry = timeSinceLastFed > 24 * 60 * 60 * 1000; // 24 hours

        if (isHungry) {
            setMood('hungry');
        } else {
            setMood('happy');
        }

        // Grow based on streak
        setSize(Math.min(3, 1 + Math.floor(streakCount / 7))); // Max size 3x
    }, [lastFed, streakCount]);

    return (
        <div className="focus-pet-container">
            <div className={`focus-pet size-${size} mood-${mood}`}>
                <div className="pet-body">
                    {/* Basic pixelated brain shape */}
                    <div className="brain-top"></div>
                    <div className="brain-bottom"></div>
                    {mood === 'happy' && <div className="sparkle"></div>}
                </div>
                <div className="pet-status">
                    {mood === 'hungry' ? 'Feed me focus!' : 'Nom nom nom!'}
                </div>
            </div>
        </div>
    );
};

export default FocusPet; 