export const API_URL = window.location.hostname.includes("sashachisler")
    ? 'https://api.sashachisler.com'
    : 'http://127.0.0.1:5000';


export const refreshAccessToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refresh_token');
        if (!refreshToken) return false;

        const response = await fetch(`${API_URL}/refresh`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${refreshToken}`
            }
        });

        if (response.ok) {
            const data = await response.json();
            localStorage.setItem('access_token', data.access_token);
            return true;
        }
        
        clearAuth();
        return false;
    } catch (error) {
        console.error('Token refresh failed:', error);
        return false;
    }
};

export const verifyAuth = async () => {
    try {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            const refreshed = await refreshAccessToken();
            if (!refreshed) return false;
        }

        const response = await fetch(`${API_URL}/whoami`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        });
        return response.ok;
    } catch (error) {
        console.error('Auth verification failed:', error);
        return false;
    }
};

export const login = async (username, password) => {
    try {
        const response = await fetch(`${API_URL}/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password }),
        });
        const data = await response.json();
        
        if (response.ok) {
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            localStorage.setItem('username', data.username);
            return { success: true };
        }
        
        return { success: false, error: data.error || 'Authentication failed' };
    } catch (error) {
        return { success: false, error: 'Network error' };
    }
};

export const clearAuth = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('username');
};