import React, { useState, useRef, useEffect, useCallback } from 'react';
import { X, Video, Circle } from 'lucide-react';
import gifshot from 'gifshot';
import { verifyAuth, refreshAccessToken } from '../utils/auth';

const API_URL = window.location.hostname.includes("sashachisler")
  ? 'https://api.sashachisler.com'
  : 'http://127.0.0.1:5000';

const RecordFocusGif = ({ onClose, onRecordingComplete }) => {
  // Move all constants inside the component
  const TARGET_WIDTH = 480;
  const TARGET_HEIGHT = 360;
  const JPEG_QUALITY = 0.6;
  const FRAME_INTERVAL = 200;     // Capture at 5fps (200ms interval)
  const TARGET_FRAME_COUNT = 50;  // We always want 50 frames in our final GIF

  // State
  const [isRecording, setIsRecording] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [gifBlob, setGifBlob] = useState(null);
  const [frames, setFrames] = useState([]);
  const [gifProgress, setGifProgress] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  
  // Refs
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const frameInterval = useRef(null);
  const timeRef = useRef(null);

  // --- Effects: Ask for camera access on mount, clean up on unmount ---
  useEffect(() => {
    console.log("Requesting camera access...");
    navigator.mediaDevices.getUserMedia({ video: true, audio: false })
      .then(stream => {
        console.log("Camera access granted");
        streamRef.current = stream;
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          
          // Set isReady when video can play
          videoRef.current.onloadedmetadata = () => {
            console.log("Video metadata loaded");
            videoRef.current.play()
              .then(() => {
                console.log("Video playback started, setting isReady");
                setIsReady(true);
              })
              .catch(err => {
                console.error("Error starting video playback:", err);
                setError('Could not start video playback');
              });
          };
        }
      })
      .catch(err => {
        console.error('Error accessing camera:', err);
        setError('Could not access camera');
      });

    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      if (frameInterval.current) {
        clearInterval(frameInterval.current);
      }
      if (timeRef.current) {
        clearInterval(timeRef.current);
      }
    };
  }, []);

  // Remove the standalone captureFrame function and move it into useEffect
  useEffect(() => {
    if (!isRecording) {
      if (frameInterval.current) {
        clearInterval(frameInterval.current);
        frameInterval.current = null;
      }
      return;
    }

    function captureFrameLocal() {
      console.log("Attempting frame capture, isRecording:", isRecording);
      if (!videoRef.current || !isReady) {
        console.log("Skipping frame capture due to:", {
          hasVideo: !!videoRef.current,
          isReady
        });
        return;
      }

      try {
        const canvas = document.createElement('canvas');
        canvas.width = TARGET_WIDTH;
        canvas.height = TARGET_HEIGHT;
        const ctx = canvas.getContext('2d');
        
        if (videoRef.current.videoWidth === 0 || videoRef.current.videoHeight === 0) {
          console.log("Video not ready:", {
            width: videoRef.current.videoWidth,
            height: videoRef.current.videoHeight
          });
          return;
        }
        
        ctx.drawImage(videoRef.current, 0, 0, TARGET_WIDTH, TARGET_HEIGHT);
        const newFrame = canvas.toDataURL('image/jpeg', JPEG_QUALITY);

        setFrames(currentFrames => {
          console.log("Adding new frame, total frames:", currentFrames.length + 1);
          const newFrames = [...currentFrames, newFrame];
          
          if (newFrames.length <= TARGET_FRAME_COUNT * 2) {
            return newFrames;
          }

          const keepEveryNth = Math.floor(newFrames.length / TARGET_FRAME_COUNT);
          const distributedFrames = newFrames.filter((_, index) => 
            index === 0 || 
            index === newFrames.length - 1 || 
            index % keepEveryNth === 0
          ).slice(0, TARGET_FRAME_COUNT);

          console.log(`Frames compressed: ${newFrames.length} -> ${distributedFrames.length}`);
          
          return distributedFrames;
        });
      } catch (error) {
        console.error("Error capturing frame:", error);
      }
    }

    captureFrameLocal(); // Capture first frame immediately
    frameInterval.current = setInterval(captureFrameLocal, FRAME_INTERVAL);

    return () => {
      if (frameInterval.current) {
        clearInterval(frameInterval.current);
        frameInterval.current = null;
      }
    };
  }, [isRecording, isReady, FRAME_INTERVAL]);

  // Update beginCapturingFrames to remove direct captureFrame reference
  const beginCapturingFrames = useCallback(() => {
    console.log("Beginning frame capture...");
    setIsRecording(true);
    setFrames([]);
    setElapsedTime(0);
    
    timeRef.current = setInterval(() => {
      setElapsedTime(prev => prev + 1);
    }, 1000);
  }, []); // Dependencies can be empty now

  // Move startRecording after beginCapturingFrames
  const startRecording = useCallback(() => {
    console.log("Starting countdown...");
    setCountdown(3);
    
    const countdownTimer = setInterval(() => {
      setCountdown(prev => {
        if (prev <= 1) {
          clearInterval(countdownTimer);
          setCountdown(null);
          beginCapturingFrames();
          return null;
        }
        return prev - 1;
      });
    }, 1000);
  }, [beginCapturingFrames]);

  // --- Stop recording & process the GIF ---
  const stopRecording = async () => {
    setIsRecording(false);

    if (frameInterval.current) {
      clearInterval(frameInterval.current);
      frameInterval.current = null;
    }
    if (timeRef.current) {
      clearInterval(timeRef.current);
      timeRef.current = null;
    }

    setIsProcessing(true);
    try {
      console.log("Final frame count:", frames.length);
      const newGifBlob = await convertFramesToGif(frames);
      setGifBlob(newGifBlob);

      const result = await handleUpload(newGifBlob);
      if (onRecordingComplete) {
        onRecordingComplete({
        });
      }
    } catch (err) {
      console.error('Error creating/uploading GIF:', err);
      setError(err.message || 'Failed to create/upload GIF');
    } finally {
      setIsProcessing(false);
      setGifProgress(0);
    }
  };

  // --- Convert frames to GIF using gifshot ---
  const convertFramesToGif = async (framesArray) => {
    return new Promise((resolve, reject) => {
      // Use the same distribution logic as in frame capture
      let finalFrames = framesArray;
      if (framesArray.length > TARGET_FRAME_COUNT) {
        const keepEveryNth = Math.floor(framesArray.length / TARGET_FRAME_COUNT);
        finalFrames = framesArray.filter((_, index) => 
          index === 0 || 
          index === framesArray.length - 1 || 
          index % keepEveryNth === 0
        ).slice(0, TARGET_FRAME_COUNT);
      }
      
      console.log(`Frames for GIF creation: ${framesArray.length} -> ${finalFrames.length}`);

      gifshot.createGIF(
        {
          images: finalFrames,
          gifWidth: TARGET_WIDTH,
          gifHeight: TARGET_HEIGHT,
          frameDuration: 0.2,
          numWorkers: 4,
          progressCallback: progress => {
            setGifProgress(Math.round(progress * 100));
          },
          quality: 5,
          sampleInterval: 1,
        },
        result => {
          setGifProgress(0);
          if (result.error) {
            return reject(new Error('GIF creation failed'));
          }
          // Convert base64 to Blob
          const base64Data = result.image.split(',')[1];
          const gifBlob = new Blob(
            [Uint8Array.from(atob(base64Data), c => c.charCodeAt(0))],
            { type: 'image/gif' }
          );
          console.log(
            `Created GIF with size: ${(gifBlob.size / 1024 / 1024).toFixed(2)} MB`
          );
          resolve(gifBlob);
        }
      );
    });
  };

  // --- Upload the final GIF blob to your API ---
  const handleUpload = async (gifBlob) => {
    try {
      // Verify auth before upload
      const isAuthenticated = await verifyAuth();
      if (!isAuthenticated) {
        throw new Error('Authentication expired. Please log in again.');
      }

      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        throw new Error('Please log in to upload videos');
      }

      const file = new File([gifBlob], `focus-${Date.now()}.gif`, {
        type: 'image/gif',
      });

      const formData = new FormData();
      formData.append('file', file);

      const xhr = new XMLHttpRequest();
      const promise = new Promise((resolve, reject) => {
        xhr.upload.addEventListener('progress', (event) => {
          if (event.lengthComputable) {
            const progress = Math.round((event.loaded / event.total) * 100);
            setUploadProgress(progress);
          }
        });

        xhr.addEventListener('load', async () => {
          if (xhr.status === 401) {
            // Try to refresh token
            const refreshed = await refreshAccessToken();
            if (refreshed) {
              // Retry upload with new token
              xhr.open('POST', `${API_URL}/api/gif-upload`);
              xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
              xhr.send(formData);
            } else {
              reject(new Error('Authentication expired'));
            }
          } else if (xhr.status >= 200 && xhr.status < 300) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(new Error(`Upload failed: ${xhr.statusText}`));
          }
        });

        xhr.addEventListener('error', () => {
          reject(new Error('Upload failed'));
        });
      });

      xhr.open('POST', `${API_URL}/api/gif-upload`);
      xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
      xhr.send(formData);

      return promise;
    } catch (err) {
      console.error('Upload error:', err);
      if (err.message.includes('Authentication expired') || 
          err.message.includes('Please log in')) {
        // Force re-authentication
        window.location.href = '/daily';
      }
      throw err;
    }
  };

  // --- Helper: format time as mm:ss ---
  const formatTime = (seconds) => {
    const m = Math.floor(seconds / 60);
    const s = seconds % 60;
    return `${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
  };

  return (
    <div className="fixed inset-0 bg-[#235390] bg-opacity-95 flex items-center justify-center z-50">
      <div className="relative max-w-4xl w-full p-4">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute -top-12 right-0 text-white hover:text-gray-200 z-50"
        >
          <X size={32} />
        </button>

        <div className="text-center mb-8">
          <h2 className="text-4xl font-bold text-white mb-4">
            Highest Possible Focus Mode
          </h2>
          <p className="text-xl text-[#b6d9fb]">
            Record a moment of your focused learning
          </p>
        </div>

        {/* Video Preview */}
        <div className="relative rounded-2xl overflow-hidden bg-black shadow-2xl mb-8 ring-4 ring-[#1cb0f6]/20">
          <video
            ref={videoRef}
            autoPlay
            playsInline
            muted
            className="w-full aspect-video object-cover"
          />

          {/* Countdown Overlay */}
          {countdown && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <span className="text-8xl font-bold text-white animate-pulse">
                {countdown}
              </span>
            </div>
          )}

          {/* Recording Indicator */}
          {isRecording && (
            <div className="absolute top-6 right-6 flex items-center gap-3 bg-black bg-opacity-70 backdrop-blur-sm px-4 py-2 rounded-full">
              <Circle className="w-3 h-3 fill-red-500 text-red-500 animate-pulse" />
              <span className="text-white font-medium">
                Recording: {formatTime(elapsedTime)}
              </span>
            </div>
          )}
        </div>

        {/* Error Display */}
        {error && <div className="text-red-500 mb-4">{error}</div>}

        {/* Control Buttons */}
        <div className="mt-4 flex justify-center gap-4">
          {!isRecording && !isProcessing && (
            <button
              onClick={startRecording}
              disabled={!isReady}
              className="py-4 px-8 bg-[#1cb0f6] hover:bg-[#1899d6] 
                         text-white rounded-2xl text-xl font-bold 
                         flex items-center justify-center gap-3 
                         transition-colors transform hover:scale-105"
            >
              <Video size={24} />
              Start Recording
            </button>
          )}

          {isRecording && (
            <button
              onClick={stopRecording}
              className="py-4 px-8 bg-red-500 hover:bg-red-600 text-white 
                         rounded-2xl text-xl font-bold flex items-center 
                         justify-center gap-3 transition-colors 
                         transform hover:scale-105"
            >
              Stop
            </button>
          )}

          {isProcessing && (
            <div className="flex flex-col items-center gap-6 py-4">
              <div className="text-white text-xl font-bold flex items-center gap-3">
                <div className="w-6 h-6 border-4 border-white border-t-[#1cb0f6] rounded-full animate-spin" />
                {gifProgress < 100 ? (
                  <>Creating your GIF... {gifProgress}%</>
                ) : (
                  <>Saving your focus... {uploadProgress}%</>
                )}
              </div>
              <div className="w-96 h-4 bg-white/20 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-[#1cb0f6] transition-all duration-300 ease-out rounded-full"
                  style={{ 
                    width: `${gifProgress < 100 ? gifProgress : uploadProgress}%`,
                    boxShadow: '0 0 10px rgba(28, 176, 246, 0.5)'
                  }}
                />
              </div>
              <p className="text-[#b6d9fb] text-lg">
                {gifProgress < 100 
                  ? `Compressing ${frames.length} frames into a perfect loop`
                  : 'Saving your focused moment...'}
              </p>
            </div>
          )}
        </div>

        <div className="text-center mt-6 text-[#b6d9fb] text-lg">
          {isRecording
            ? "Keep going as long as you like. We'll compress on the fly!"
            : countdown
            ? "Get ready to show your focus!"
            : "Click 'Start Recording' when ready."}
        </div>
      </div>
    </div>
  );
};

export default RecordFocusGif;
